import React from 'react';

const ClientsList = () => {
    return (
        <div className="client-items clearfix">
            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/qualtia.webp" alt="Logo" />
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/alpura.webp" alt="Logo" />
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/citrofood.webp" alt="Logo" />
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/cordova.webp" alt="Logo" />
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/foodservice.webp" alt="Logo" />
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/ryder.webp" alt="Logo" />
                </div>
            </div>

            <div className="client-item">
                <div className="client-body">
                    <img src="assets/img/clients/xo.webp" alt="Logo" />
                </div>
            </div>
        </div>
    );
};

export default ClientsList;
