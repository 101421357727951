import React, { Component } from 'react';

class PageTitleHome extends Component {
    constructor() {
        super();
        this.state = {
            lang: 'Es',
        };

        this.onChangeValue = this.onChangeValue.bind( this );
    };

    onChangeValue( event ) {
        this.setState( {
            lang: event.target.title,
        } );
    };

    render() {
        return (
            <section id="page-title" className="block" style={ { backgroundImage: `url( ${ require( '../../assets/img/home/home-main-img.webp' ) } )` } }>
                <div className="wrapper h-100">
                    <div className="d-flex justify-content-between position-relative h-100">
                        <div className="align-self-center">
                            <div className="title">
                                <h1>Krad <br/>Construcciones</h1>
                            </div>  

                            <p className="spacer p-top-lg mb-0">
                                Somos una empresa líder en construcción y servicios relacionados ofrecemos la más alta calidad. 
                                Nuestros servicios incluyen la construcción de estructuras, el establecimiento de infraestructura, el diseño de interiores y exteriores, 
                                así como la instalación de sistemas de seguridad.
                            </p>
                        </div>

                        {/* <div className="lang-position">
                            <nav className="languages">
                                <ul className="nav" onClick={ this.onChangeValue }>
                                    <LanguageSelector title="En" className={ this.state.lang === 'Es' ? 'current-lang' : '' } />
                                    <LanguageSelector title="Ru" className={ this.state.lang === 'En' ? 'current-lang' : '' } />
                                </ul>
                            </nav>
                        </div> */}
                    </div>
                </div>

                <div className="page-title-bg-color"></div>
            </section>
        );
    };
};

export default PageTitleHome;
