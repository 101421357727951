import { initializeApp } from "firebase/app";

// creare a global variable call app a init in null

const initFirebase = (app) => {
    const firebaseConfig = {
        apiKey: "AIzaSyA453Qfg9CUIFycFUM--grYobvAQf7yrzE",
        authDomain: "krad-construcciones-landing.firebaseapp.com",
        projectId: "krad-construcciones-landing",
        storageBucket: "krad-construcciones-landing.appspot.com",
        messagingSenderId: "68334217442",
        appId: "1:68334217442:web:6d9a5e3dc37639e144d6bb",
        measurementId: "G-VWTDWTYLL8"
    }
    app = initializeApp(firebaseConfig);
    return app;
}

export default initFirebase;