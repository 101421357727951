import proyectsAndDesign from '../data/service-inside/serviceContentProyectDesign.json'
import mantaining from '../data/service-inside/mantaining.json';
import civilbuildingData from '../data/service-inside/civilbuilding.json';
import wells from '../data/service-inside/wells.json';
import civilengineering from '../data/service-inside/civilengineering.json';
import paving from '../data/service-inside/paving.json';
import plumbing from '../data/service-inside/plumbing.json';
import coolerchambers from '../data/service-inside/coolerchambers.json';
import metallicstructure from '../data/service-inside/metallicstructure.json'; 
import defaultService from '../data/service-inside/defaultServices.json';

const seccionsData = (key) => {
    if (key ===  'proyectsanddesign') {
        return proyectsAndDesign
    }else if (key === 'civilengineering') {
        return civilengineering
    } else if (key === 'mantaining') {
        return mantaining
    } else if (key === 'paving') {
        return paving
    } else if (key === 'coolerchambers') {
        return coolerchambers
    } else if (key === 'metallicstructure') {
        return metallicstructure
    } else if (key === 'civilbuilding') {
        return civilbuildingData
    } else if (key === 'plumbing') {
        return plumbing
    } else if (key === 'wells') {
        return wells
    } else {
        return defaultService
    }

}

export default seccionsData;