import React from 'react';

const WhyUsPdf = () => {

    return (
        <a className="transform-scale-h w-100 h-100" href="https://firebasestorage.googleapis.com/v0/b/krad-construcciones-landing.appspot.com/o/CV%20Krad%20Construcciones.pdf?alt=media&token=075baff1-b550-48fe-b23a-73e8a6c50003" rel="noopener noreferrer" target="_blank">
            <div className="bg-dark padding-md text-white h-100">
                <div className="d-flex justify-content-between spacer m-bottom-sm">
                    <div className="align-self-center">
                        <p className="p-large bold">Servicios & Soluciones</p>
                    </div>

                    <div className="align-self-center">
                        <p>
                            <i className="fas fas-space-l fa-file-download"></i>
                        </p>
                    </div>
                </div>

                <p>Descarga nuestro PDF hacerca de nuestros Servicios & Soluciones.</p>
            </div>
        </a>
    );
}

export default WhyUsPdf;
