import React from 'react';

const ServiceInsideWidget = (data) => {

    const services = data.services.map((item, idx) => (
        <p key={idx}>{ item }</p>
    ))

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Ofrecemos</h5>

                <div className="card-text">
                    { services }
                </div>
            </div>

            <div className="card-link">
                <a className="btn btn-secondary btn-lg w-100" href={ process.env.PUBLIC_URL + '/contacts' }>Obten tu cotización</a>
            </div>
        </div>
    );
};

export default ServiceInsideWidget;
