import React from 'react';

const AboutUsSecondary = () => {
    return (
        <div className="spacer m-top-lg">
            <p>
            Nuestro compromiso es ofrecer soluciones innovadoras para satisfacer las necesidades de nuestros clientes.
             Estamos totalmente comprometidos con la seguridad, la calidad y el cumplimiento de las normas y regulaciones legales. 
             Nuestro equipo de profesionales es una selección de los mejores y más experimentados especialistas en la industria de la construcción.
            </p>
            <p>Estamos orgullosos de nuestros logros y trabajamos constantemente para seguir mejorando.</p>
        </div>
    );
};

export default AboutUsSecondary;
