import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleCommon from '../blocks/page-title/PageTitleCommon';
import ServiceInsideSwiper from '../blocks/service-inside/ServiceInsideSwiper';
import ServiceInsideWidget from '../blocks/service-inside/ServiceInsideWidget';

import seccionsData from '../helpers/seccions'

const ServiceInside = () => {
    
    const urlParams = new URLSearchParams(window.location.search)
    const seccion = urlParams.get("k")
    let content = seccionsData(seccion)

    useEffect( () => {
        document.body.classList.add( 'single' );
        document.body.classList.add( 'single-adveits_service' );

        return () => {
            document.body.classList.remove( 'single' )
            document.body.classList.remove( 'single-adveits_service' )
        }
    }, [] );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Grupo Krad | { content.title  } </title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleCommon title="Ship logistics" />

                <section id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div className="row gutter-width-md single-content">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                                        <ServiceInsideSwiper />

                                        <div className="description style clearfix spacer m-top-lg">
                                            <h5>{ content.mainAbstract }</h5>
                                            <p>{ content.bodyContent }</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                        <aside id="aside" className="widget-area style-default">
                                            <div id="service" className="widget_service">
                                                <ServiceInsideWidget  services={content.servicesIncludes}/>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default ServiceInside;
