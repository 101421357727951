import React from 'react';

const LinkTitle = () => {
    return (                    
        <a title="About us" className="transform-scale-h" href={ `${ process.env.PUBLIC_URL + "/about-us" }` }>
            Sobre Nosotros<i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
        </a>
    );
};
  
const TextTitle = () => {
    return ( <div title="About us">Sobre Nosotros</div> );
};

const AboutUsTitle = ( props ) => {

const isHomepage = props.isHomepage;

    if ( isHomepage ) {
        return <LinkTitle />;
    }
        return <TextTitle />;
};

const AboutUsPrimary = ( props ) => {
    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="title">
                <h2>
                    <AboutUsTitle isHomepage={ props.isHomepage } />
                </h2>
            </div>

            <div className="text">
                <p>
                Nuestra empresa está dedicada a la construcción y remodelación de proyectos arquitectónicos comerciales e industriales. 
                Durante más de 15 años hemos demostrado nuestra experiencia, ética y calidad en el trabajo, lo que nos ha permitido destacarnos 
                como una empresa líder.
                </p>
                <p>
                Ofrecemos servicios en la planeación, diseño y ejecución de todo tipo de proyectos, tanto en el ámbito comercial como industrial. 
                Contamos con un personal altamente calificado que se esfuerza por ofrecer el mejor servicio a nuestros clientes.
                </p>
            </div>

            <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default">

                <div className="list-group-item">
                    <h4 className="text-secondary">+120</h4>
                    <p>Obras terminadas</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPrimary;
