import React, { Component } from 'react';
import initializeApp from '../../helpers/firebase.conf.js';
import { getFirestore, collection, addDoc } from "firebase/firestore";

class ShortCodeModalForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            values: {
                name: '',
                lastName: '',
                email: '',
                company: '',
                phone: '',
                message: '',
            },
            successMessage: "Gracias por contactarnos, nos comunicaremos contigo lo antes posible.",
            warningMessage: 'Llena todos los campos por favor!',
            errorMessage: 'A ocurrido un error, intenta de nuevo',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000
        };
    };

    submitForm = async ( e ) => {
        e.preventDefault();

        if ( document.querySelector( '#alert' ) ) {
            document.querySelector( '#alert' ).remove();
        }

        this.setState( { isSubmitting: true } );

        const app = initializeApp();
        const db = getFirestore(app);

        const email = this.state.values.email;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const name = this.state.values.name + ' ' + this.state.values.lastName;
        const message = this.state.values.message;
        const company = this.state.values.company;
        const phone = this.state.values.phone;

          if ( ! regex.test( email ) ) {
            this.callAlert( 'Por favor ingresa un correo valido.', 'warning' );
            return;
        }
        if (name.length < 3) {
            this.callAlert( 'Por favor ingresa un nombre valido.', 'warning' );
            return;
        }
        if (message.length < 20) { 
            this.callAlert( 'El mensaje no puede ser tan corto.', 'warning' );
            return;
        }
        if (company.length < 3) {
            this.callAlert( 'Por favor ingresa un nombre valido.', 'warning' );
            return;
        }
        if (phone.length < 10) {
            this.callAlert( 'Por favor ingrese un número de telefono valido (10 dígitos).', 'warning' );
            return;
        }

        const emailContent = {
            to: 'contacto@kradconstrucciones.com',
            message: {
                subject: "Cliente Interesado - Krad Construcciones",
                html: `<p>Hay un nuevo cliente interesado estos son sus datos, contactalo lo antes posible</p>
                <p>Nombre: ${ name }</p>
                <p>Correo: ${ email }</p>
                <p>Empresa: ${company}</p>
                <p>Teléfono: ${phone}</p>
                <p>Mensaje: ${message}</p>`
            }
        }

        try {
            await addDoc(collection(db, "Krad Landing Mail"), emailContent);
            this.callAlert( '¡Gracias! Por contactarnos nos comunicaremos contigo lo antes posible!.', 'success' );
        } catch (error) {
            console.error('Error', error);
            this.callAlert( 'Lo lamentamos de momento no pudimos procesar su solicitud.', 'error' );
        } finally { 
            this.setState( { isSubmitting: false } );
        }

    };

    removeAlert = () => {
        clearTimeout( this.state.alertTimeout );
        this.setState( {
            alertTimeout: setTimeout( function() {
                var element = document.querySelector( '#alert' );
                element.classList.remove( 'fadeIn' );
                element.classList.add( 'fadeOut' );
                setTimeout( function() {
                    element.remove();
                }, 900 );
            }, this.state.delay ),
        } );
    };

    callAlert = ( message, type ) => {
        if ( ! document.querySelector( '#alert' ) ) {
            if ( type === 'success' ) {
                this.setState( { alertClass: 'success' } );
            }

            if ( type === 'error' ) {
                this.setState( { alertClass: 'danger' } );
            }

            if ( type === 'warning' ) {
                this.setState( { alertClass: 'warning' } );
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector( '.contact-form' );

            element.insertAdjacentHTML( 'beforeend', alert );

            this.removeAlert();
        }
    };

    handleInputChange = ( e ) => {
        this.setState( {
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value,
            },
        } );
    };

    render() {
        return (
            <form onSubmit={ this.submitForm } method="post" action="form.php" className="contact-form">
                <div className="form-group">
                    <label className="after" htmlFor="message">¿Cómo te podemos ayudar?</label>
                    <textarea value={ this.state.values.message } onChange={ this.handleInputChange } name="message" className="form-lg" id="message" required="required"></textarea>
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="name">Nombre</label>
                    <input value={ this.state.values.name } onChange={ this.handleInputChange } name="name" type="text" className="form-lg" id="name" required="required" placeholder="Alberto" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="lastName">Apellido</label>
                    <input value={ this.state.values.lastName } onChange={ this.handleInputChange } name="lastName" type="text" className="form-lg" id="lastName" required="required" placeholder="Hernandez" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="email">Correo</label>
                    <input value={ this.state.values.email } onChange={ this.handleInputChange } name="email" type="email" className="form-lg" id="email" required="required" placeholder="email@gmail.com" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="company">Empresa</label>
                    <input value={ this.state.values.company } onChange={ this.handleInputChange } name="company" type="text" className="form-lg" id="company" required="required" placeholder="Empresa" />
                </div>

                <div className="form-group">
                    <label className="after" htmlFor="phone">No. Teléfono</label>
                    <input value={ this.state.values.phone } onChange={ this.handleInputChange } name="phone" type="number" className="form-lg" id="phone" required="required" placeholder="55 7623 1252" />
                </div>

                <button type="submit" className="btn btn-secondary transform-scale-h">Enviar</button>
            </form>
        );
    };
};

export default ShortCodeModalForm;