import React from 'react';

import FooterMenu from './FooterMenu';

const FooterWidget = () => {
    return (
      <div className="footer-widgets">
          <div className="footer-widget-area d-flex flex-wrap">
              <div className="widget">
                  <h5 className="widget-title">Sobre Nosotros</h5>

                  <p>Somos una empresa Méxican con más de 15 años de experiencia en DIseño, Gestion, Mantenimiento y Desarrollo de proyectos en el sector de construccion, trabajamos bajo la eficiencia </p>
              </div>

              <div className="widget">
                  <h5 className="widget-title">Nosotros</h5>

                  <FooterMenu />
              </div>

              <div className="widget">
                  <h5 className="widget-title">Contacto</h5>

                  <p>Canal del Nte. 429, San Martin Tepetlixpan, 54870 Cuautitlán Izcalli, México.</p>

                  <p><a href="mailto:contacto@kradconstrucciones.com">contacto@kradconstrucciones.com</a></p>

                  <p><a href="tel:+525549942554">Oficina: 55 4994 2554</a></p>
              </div>
          </div>
      </div>
    );
};

export default FooterWidget;
