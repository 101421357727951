import React from 'react';

import WhyUsPdf from './WhyUsPdf';

const WhyUsContent = () => {
    return (
        <div className="wrapper">
            <div className="content">
                <div className="clearfix">
                    <div className="row gutter-width-lg with-pb-lg style-default">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">01</h4>
                            <p className="text-primary p-large bold">Experiencia</p>
                            <p>Somos una empresa con mas de 20 años de experiencia en el sector de construcción y contamos con la confianza de grandes empresas de la industría méxicana</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">02</h4>
                            <p className="text-primary p-large bold">Talento</p>
                            <p>Trabajamos con los mejores profesionales del sector de construcción para asegurarte un resultado excepcional, cumpliendo con los más altos estándares de calidad</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">03</h4>
                            <p className="text-primary p-large bold">Calidad</p>
                            <p>Todas nuestras obras cumplen en calidad y cuentan con garantía de durabilidad</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">04</h4>
                            <p className="text-primary p-large bold">Eficiencia en costos</p>
                            <p>Nuestra experiencia nos permite entregarte el mejor rendimiento en base a tú presupuesto</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">05</h4>
                            <p className="text-primary p-large bold">Gestion del Riesgo</p>
                            <p>Nos encargamos de gestionar y prevenir todos los contratiempos del proyecto así tú no tendras que preocuparte de nada</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <WhyUsPdf />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUsContent;
