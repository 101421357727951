import React, { Component } from 'react';
import seccionsData from '../../helpers/seccions'

class PageTitleCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'En',
        };

        this.onChangeValue = this.onChangeValue.bind( this );

        const urlParams = new URLSearchParams(window.location.search)
        const seccion = urlParams.get("k")
        this.content = seccionsData(seccion)

        if (!seccion && this.props.title) {
            this.content.title = this.props.title
        }
    };

    componentDidUpdate () {
        const urlParams = new URLSearchParams(window.location.search)
        const seccion = urlParams.get('k')
        this.content = seccionsData(seccion)
    }

    onChangeValue( event ) {
        this.setState( {
            lang: event.target.title,
        } );
    };

    render() {
        return (
            <section id="page-title" className="block" style={ { backgroundImage: `url( ${ (this.content.mainImage) } )` } }>
                <div className="wrapper h-100">
                    <div className="d-flex justify-content-between position-relative h-100">
                        <div className="align-self-center">
                            <div className="title">
                                <h1>{ this.content.title }</h1>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="page-title-bg-color"></div>
            </section>
        );
    };
};

export default PageTitleCommon;
